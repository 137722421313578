import { instance } from '../../config/api';

export const getAssets = async ({ queryKey }) => {
  const [, query] = queryKey;
  const { data } = await instance.get(`/assets`, { params: query });
  return data;
};

export const getAssetById = async ({ queryKey }) => {
  const [, assetId] = queryKey;
  const { data } = await instance.get(`/assets/${assetId}`);
  return data;
};

export const createAsset = async ({ userId, payload }) => {
  const { data } = await instance.post(`/users/${userId}/assets`, payload);
  return data;
};

export const editAsset = async ({ userId, assetId, payload }) => {
  const { data } = await instance.put(
    `users/${userId}/assets/${assetId}`,
    payload,
  );
  return data;
};

export const confirmAssetUpload = async ({ userId, assetId, payload }) => {
  const { data } = await instance.put(
    `users/${userId}/assets/${assetId}/confirmUpload`,
    payload,
  );
  return data;
};

export const getAssetUploadSignedUrl = async ({
  userId,
  assetId,
  fileName,
}) => {
  const { data } = await instance.get(
    `users/${userId}/assets/${assetId}/uploadSignedUrl`,
    {
      params: {
        fileName,
      },
    },
  );
  return data;
};

export const deleteAsset = async ({ userId, assetId, payload }) => {

  console.log('userId', userId);
  const { data } = await instance.delete(
    `users/${userId}/assets/${assetId}`,
    payload,
  );
  return data;
};
