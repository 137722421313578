import { memo, useCallback, useEffect, useState } from 'react';
import { Button, Form, Image, InputGroup, Modal, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useDeleteUser, useEditUser } from '../../hooks/data';
import { handleError } from '../../utils';
import CustomButton from '../Buttons/CustomButton';
import { useIsAdmin } from '../../hooks/user';

const initialState = {
  username: '',
  name: '',
  bio: '',
  image: '',
  twitter: '',
  role: '',
};

const ProfileModal = ({ profileData, onHideModal }) => {
  const isAdmin = useIsAdmin();

  const {
    mutate: editUser,
    isSuccess: editUserSuccess,
    isLoading: isEditingUser,
  } = useEditUser();

  const { mutate: deleteUser, isSuccess: deleteUserSuccess } = useDeleteUser();

  const [formData, setFormData] = useState({ ...initialState, ...profileData });

  useEffect(() => {
    if (profileData) {
      setFormData({
        ...initialState,
        ...profileData,
        image: profileData?.avatar,
      });
    }

    return () => {
      setFormData(initialState);
    };
  }, [profileData]);

  const _handleClose = () => {
    setFormData(initialState);
    onHideModal();
  };

  useEffect(() => {
    if (editUserSuccess) _handleClose();
  }, [editUserSuccess]);

  useEffect(() => {
    if (deleteUserSuccess) _handleClose();
  }, [deleteUserSuccess]);

  const _handleFieldChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [key]: value });
  };

  const _handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file.size > 5000000) {
      toast.error('File size should be less than 5MB');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      console.log('image loaded');
      setFormData({ ...formData, image: reader.result });
    };
    reader.onerror = (error) => {
      handleError(error, { fallbackMessage: 'Could not load image' });
    };
    reader.readAsDataURL(file);
  };

  const _handleSubmit = () => {
    // check if any required fields are missing and show an error
    if (!formData.userName) {
      toast.error('Please fill all required fields');
      return;
    }

    const payload = { ...formData };

    if (payload.image === payload?.avatar) delete payload.image;

    editUser({ userId: profileData._id, user: payload });
  };

  const _handleDelete = () => {
    deleteUser({ userId: profileData._id });
  };

  return (
    <Modal
      show={profileData}
      onHide={_handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="userName">
            <Form.Label>Username</Form.Label>
            <Form.Control
              name="userName"
              type="text"
              onChange={_handleFieldChange}
              defaultValue={formData?.userName}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              onChange={_handleFieldChange}
              defaultValue={formData?.name}
            />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="bio">
            <Form.Label>Bio</Form.Label>
            <Form.Control
              name="bio"
              as="textarea"
              rows={3}
              onChange={_handleFieldChange}
              defaultValue={formData?.bio}
            />
          </Form.Group>

          {formData?.image ? (
            <Stack gap={2} className="mb-3">
              <Image width={'150px'} src={formData?.image} alt="image" />
              <Link
                title="remove image"
                to=""
                variant="danger"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({ ...formData, image: null });
                }}
              >
                Remove Image
              </Link>
            </Stack>
          ) : (
            <Form.Group className="mb-3" controlId="image">
              <Form.Label>Avatar</Form.Label>
              <Form.Control
                name="image"
                type="file"
                onChange={_handleImageChange}
              />
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Label>Twitter</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>@</InputGroup.Text>
              <Form.Control
                maxLength={40}
                name="twitter"
                type="text"
                onChange={_handleFieldChange}
                defaultValue={formData?.twitter}
              />
            </InputGroup>
          </Form.Group>
          {isAdmin && (
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    role: e.target.value,
                  })
                }
                value={formData.role}
              >
                <option value="admin">Admin</option>
                <option value="creator">Creator</option>
                <option value="user">User</option>
                {/* Add more roles if necessary */}
              </Form.Control>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {isAdmin && (
          <CustomButton
            variant="outline-danger"
            type="submit"
            onClick={_handleDelete}
            className="me-auto"
          >
            Delete
          </CustomButton>
        )}

        <Button variant="outline-dark" onClick={_handleClose}>
          Cancel
        </Button>
        <CustomButton
          variant="primary"
          type="submit"
          onClick={_handleSubmit}
          isLoading={isEditingUser}
        >
          Save
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(ProfileModal);
