/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  useAddAssetToLayer,
  useGetAssets,
  useGetLayer,
  useRemoveAssetFromLayer,
} from '../../hooks/data';
import CustomButton from '../Buttons/CustomButton';
import ExplorerMapBoxMini from '../ExplorerMapboxMini';
import { useIsAdmin } from '../../hooks/user';
import { FaTimesCircle } from 'react-icons/fa';

const initialState = {
  discoverability: 'unrestricted',
  assetArray: [],
};

// Define a new component that will render the option with an image and the text
const Option = ({ data, innerProps, isFocused }) => {
  return (
    <div
      {...innerProps}
      style={{
        padding: '10px',
        backgroundColor: isFocused ? '#f0f0f0' : 'white',
      }}
    >
      <img
        src={data.imageUri}
        alt={data.label}
        style={{ width: '20px', height: '20px', marginRight: '10px' }}
      />
      {data.label}
    </div>
  );
};
const TetheringModal = ({ layerId, onHideModal }) => {
  const isAdmin = useIsAdmin();

  const { data: campaignData } = useGetLayer(layerId);
  const { mutate: removeFromLayer } = useRemoveAssetFromLayer();

  const { data: assets = [] } = useGetAssets(
    isAdmin ? {} : { owner: campaignData?.owner },
  );

  const untetheredAssets = useMemo(() => {
    return assets.filter((asset) => {
      if (!asset.published) return false;
      return !campaignData?.pins.find(
        (attachedAsset) => attachedAsset._id === asset._id,
      );
    });
  }, [assets, campaignData]);

  const {
    mutate: addToLayer,
    isLoading: isAddingItemToLayer,
    isSuccess: hasAddedItemToLayer,
  } = useAddAssetToLayer();
  const [formData, setFormData] = useState({ ...initialState });
  const [isTethering, setIsTethering] = useState(false);

  const _handleCancel = () => {
    setFormData(initialState);
    onHideModal();
  };

  const _handleSubmit = () => {
    if (!formData.assetArray.length) {
      toast.error('Please select a collectable');
      return;
    }
    // if (!formData.discoverability) {
    //   toast.error('Please specify item discoverability');
    //   return;
    // }
    // if (formData.discoverability === 'location' && !currentAsset?.collectionZones?.length) {
    //   toast.error('Please pick a location or mark the item as unrestricted');
    //   return;
    // }

    formData.assetArray.forEach((asset) => {
      addToLayer({ layerId: campaignData._id, assetId: asset.value });
    });
  };

  const options = useMemo(
    () =>
      untetheredAssets.reverse().map((asset) => ({
        value: asset._id,
        label: asset.title,
        imageUri: asset.imageUri, // include the image URI in the option
      })),
    [untetheredAssets],
  );

  const currentAsset = useMemo(
    () => untetheredAssets.find((c) => c._id === formData.assetId),
    [formData.assetId, untetheredAssets],
  );

  useEffect(() => {
    if (hasAddedItemToLayer) _handleCancel();
  }, [hasAddedItemToLayer]);

  return (
    <Modal
      show={campaignData}
      onHide={_handleCancel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Link Creations to Layer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {campaignData?.pins?.length > 0 && (
          <div className="my-4">
            <div className="d-flex flex-wrap">
              {campaignData?.pins?.map((pin) => (
                <div
                  key={pin._id}
                  className="d-flex flex-column align-items-center justify-content-center m-2 position-relative"
                  style={{ width: '100px', height: '100px' }}
                >
                  <div
                    className="position-absolute top-0 end-0 bg-white rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      cursor: 'pointer',
                      padding: '2px',
                      width: '20px',
                      height: '20px',
                      color: 'black',
                    }}
                    onClick={() => {
                      removeFromLayer({ layerId, assetId: pin._id });
                    }}
                  >
                    <FaTimesCircle />
                  </div>
                  <img
                    src={pin.imageUri}
                    alt={pin.title}
                    width="80"
                    height="80"
                    style={{ objectFit: 'cover' }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        <Form>
          <Form.Group className="mb-3" controlId="assetId">
            <Form.Label>Choose collectable(s)</Form.Label>
            <Select
              isMulti
              placeholder="Select one or more items"
              options={options}
              // value={options?.find((p) => p.value === formData.assetId) ?? null}
              onChange={(e) => setFormData({ ...formData, assetArray: e })}
              components={{
                Option, // pass the Option component to the components prop
              }}
            />
          </Form.Group>

          {/* {formData?.assetArray?.length > 0 && (
            <Form.Group className="mb-3" controlId="discoverability">
              <Form.Label>Discoverability</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="discoverability"
                defaultValue={formData?.discoverability || 'unrestricted'}
                onChange={(e) => setFormData({ ...formData, discoverability: e.target.value })}
              >
                <option value="">Please select</option>
                <option value={'location'}>Location based - Asset can only be discovered in certain Dropzones</option>
                <option value={'unrestricted'}>Unrestricted - Asset is available everywhere</option>
              </Form.Select>
            </Form.Group>
          )} */}

          {formData?.discoverability === 'location' &&
            untetheredAssets.length > 0 && (
              <ExplorerMapBoxMini
                layer={campaignData}
                currentAsset={currentAsset}
                onMarkerToTetherClick={(status) => {
                  setIsTethering(status);
                }}
              />
            )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-dark"
          onClick={_handleCancel}
          className="me-auto"
        >
          Cancel
        </Button>
        <CustomButton
          isLoading={isAddingItemToLayer}
          variant="primary"
          type="submit"
          onClick={_handleSubmit}
          disabled={isTethering}
        >
          Link
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default TetheringModal;
