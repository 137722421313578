import { useAppStore } from '../store';

export const useGetCurrentUser = () => {
  const user = useAppStore((state) => state.user);
  return user;
};

export const useGetIsLoggedIn = () => {
  const user = useGetCurrentUser();
  return !!user;
};

export const useGetCurrentUserId = () => {
  const user = useGetCurrentUser();
  return user?._id;
};

export const useIsSelf = (userName) => {
  const user = useGetCurrentUser();
  return user?.userName === userName;
};

export const useIsAdmin = () => {
  const user = useGetCurrentUser();
  return user?.role === 'admin';
};

export const useIsCreator = () => {
  const user = useGetCurrentUser();
  return user?.role === 'creator'; // || user?.role === 'user';
};

export const useIsOwner = (resource) => {
  const userId = useAppStore((state) => state.user?._id);

  const owner = resource?.owner;
  if (!owner) return false;
  return userId === owner.toString();
};

export const useIsAdminOrOwner = (resource) => {
  const isAdmin = useIsAdmin();
  const isOwner = useIsOwner(resource);

  return isAdmin || isOwner;
};
