import React, { useState } from 'react';
import BootstrapTable from '@musicstory/react-bootstrap-table-next';
import paginationFactory from '@musicstory/react-bootstrap-table2-paginator';
import filterFactory, {
  textFilter,
} from '@musicstory/react-bootstrap-table2-filter';
import moment from 'moment';
import { useGetUsers } from '../../hooks/data';
import ProfileModal from '../Modals/ProfileModal';

const UsersTable = () => {
  const { data: userList = [] } = useGetUsers();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const getRowStyle = (row, rowIndex) => {
    let baseStyle = {
      verticalAlign: 'middle',
      cursor: 'pointer',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    }; // Default style, this is equivalent to tableCellCentered

    if (row.role === 'admin') {
      return { ...baseStyle, '--bs-table-bg': '#ff61b1' };
    } else if (row.role === 'creator') {
      return { ...baseStyle, '--bs-table-bg': 'yellow' };
    }

    return baseStyle;
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowClick(row);
    },
  };

  const handleRowClick = (userData) => {
    setSelectedUser(userData);
    setShowEditModal(true);
  };

  const resetModals = () => {
    setShowEditModal(false);
    setSelectedUser(null);
  };

  const columns = [
    {
      dataField: 'avatar',
      text: 'Avatar',
      formatter: (cellContent, user) => (
        <img
          src={user.avatar}
          alt={`Avatar of ${user.userName}`}
          width="35"
          height="35"
        />
      ),
    },
    {
      dataField: 'userName',
      text: 'User Name',
      filter: textFilter({ placeholder: '' }),
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter({ placeholder: '' }),
      sort: true,
    },
    {
      dataField: 'emailAddress',
      text: 'Email Address',
      filter: textFilter({ placeholder: '' }),
      sort: true,
    },
    {
      dataField: 'twitter',
      text: 'Twitter handle',
      filter: textFilter({ placeholder: '' }),
      sort: true,
    },
    {
      dataField: 'lastLogin',
      text: 'Last login',
      sort: true,
      formatter: (cell) =>
        cell ? moment(cell).format('DD MMM YYYY hh:mm A') : '',
    },
    {
      dataField: 'role',
      text: 'Role',
      sort: true,
    },
  ];

  return (
    <>
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={userList}
        columns={columns}
        pagination={paginationFactory({ sizePerPage: 100 })}
        filter={filterFactory()}
        rowStyle={getRowStyle}
        rowEvents={rowEvents}
        hover
      />
      {showEditModal && (
        <ProfileModal
          profileData={selectedUser}
          onHideModal={() => resetModals()}
        />
      )}
    </>
  );
};

export default UsersTable;
