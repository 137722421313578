import React, { useCallback, useState } from 'react';
import { Button, Container, Stack } from 'react-bootstrap';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { useGetCurrentUser, useIsAdmin } from '../../hooks/user';
import AssetModal from '../Modals/AssetModal';

const AssetFilter = ({ count, onChange }) => {
  const [showCreateAssetModal, setShowCreateAssetModal] = useState(false);
  const { userName } = useParams();
  const currentUser = useGetCurrentUser();
  const isAdmin = useIsAdmin(userName);

  const handleChange = useCallback(
    (e) => {
      onChange(e.value);
    },
    [onChange],
  );

  const filters = [{ value: 'list', label: 'List View' }];

  const styles = {
    menu: (provided) => ({
      ...provided,
      width: 'max-content',
      zIndex: 200,
      minWidth: '160px',
    }),
  };

  return (
    <>
      <Container fluid className="filter-sort-bar">
        <Stack
          direction="horizontal"
          gap={3}
          className="align-items-center justify-content-between"
        >
          <h2 className="h6 mb-0">{`${count || 'No'} items`}</h2>

          <Stack direction="horizontal" gap={3} className="align-items-center">
            <Select
              menuPlacement="auto"
              options={filters}
              defaultValue={filters[0]}
              onChange={handleChange}
              styles={styles}
            />
            <Button
              variant="secondary"
              onClick={() => {
                if (!isAdmin && currentUser?.stats?.assetCount >= 10) {
                  alert(
                    'You have reached the maximum number of assets allowed for your account. We will be adding the ability to upgrade soon!',
                  );
                  return;
                } else {
                  setShowCreateAssetModal(true);
                }
              }}
            >
              Upload Creation
            </Button>
          </Stack>
        </Stack>
      </Container>

      {showCreateAssetModal && (
        <AssetModal
          data={{}}
          onHideModal={() => setShowCreateAssetModal(false)}
        />
      )}
    </>
  );
};

export default AssetFilter;
