import React, { useCallback, useState } from 'react';
import { Button, Container, Stack } from 'react-bootstrap';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { useGetCurrentUser, useIsAdmin, useIsCreator } from '../../hooks/user';
import LayerModal from '../Modals/CampaignModal';

const CampaignFilter = ({ count, onChange }) => {
  const [showCreateCampaignModal, setShowCreateCampaignModal] = useState(false);
  const { userName } = useParams();
  const currentUser = useGetCurrentUser();
  const isAdmin = useIsAdmin(userName);
  const isCreator = useIsCreator(userName);

  const handleChange = useCallback(
    (e) => {
      onChange(e.value);
    },
    [onChange],
  );

  const filters = [{ value: 'list', label: 'List View' }];

  const styles = {
    menu: (provided) => ({
      ...provided,
      width: 'max-content',
      zIndex: 200,
      minWidth: '160px',
    }),
  };

  return (
    <>
      <Container fluid className="filter-sort-bar">
        <Stack
          direction="horizontal"
          gap={3}
          className="align-items-center justify-content-between"
        >
          <h2 className="h6 mb-0">{`${count || 'No'} items`} </h2>

          <Stack direction="horizontal" gap={3} className="align-items-center">
            <div style={{ minWidth: '160px' }}>
              <Select
                menuPlacement="auto"
                options={filters}
                defaultValue={filters[0]}
                onChange={handleChange}
                styles={styles}
              />
            </div>
            {isAdmin || isCreator ? (
              <Button
                variant="secondary"
                onClick={() => {
                  if (!isAdmin && currentUser?.stats?.layerCount >= 1) {
                    alert(
                      'You have reached the maximum number of layers allowed for your account. We will be adding the ability to upgrade soon!',
                    );
                    return;
                  } else {
                    setShowCreateCampaignModal(true);
                  }
                }}
              >
                New Layer
              </Button>
            ) : null}
          </Stack>
        </Stack>
      </Container>

      {showCreateCampaignModal && (
        <LayerModal
          data={{}} // TODO: Improve this.. I don't like it
          onHideModal={() => setShowCreateCampaignModal(false)}
        />
      )}
    </>
  );
};

export default CampaignFilter;
