import React, { memo, useState } from 'react';
import BootstrapTable from '@musicstory/react-bootstrap-table-next';
import paginationFactory from '@musicstory/react-bootstrap-table2-paginator';
import filterFactory, {
  textFilter,
} from '@musicstory/react-bootstrap-table2-filter';
import moment from 'moment';

import PromoModal from '../Modals/PromoModal';
import PromosFilter from '../Filters/PromosFilter';
import { FaCommentAlt, FaEdit } from 'react-icons/fa';
import PushMessageModal from '../Modals/PushMessageModal';

const PromosTable = ({ data }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [showCampaignPushMessageModal, setShowCampaignPushMessageModal] =
    useState(false);

  const getRowStyle = (row, rowIndex) => {
    let baseStyle = {
      verticalAlign: 'middle',
      cursor: 'pointer',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    }; // Default style, this is equivalent to tableCellCentered

    if (row.active) {
      return { ...baseStyle, '--bs-table-bg': '#fff' };
    } else {
      return { ...baseStyle, '--bs-table-bg': '#fc018429' };
    }
  };

  const resetModals = () => {
    setShowEditModal(false);
    setSelectedPromo(null);
    setShowCampaignPushMessageModal(false);
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowClick(row);
    },
  };

  const handleRowClick = (data) => {
    setSelectedPromo(data);
    setShowEditModal(true);
  };

  const handlePushMessageClick = (data) => {
    setSelectedPromo(data);
    setShowCampaignPushMessageModal(true);
  };

  const defaultSorted = [
    {
      dataField: 'updatedAt', // name of the field you want to sort by default
      order: 'desc', // or 'asc' for ascending
    },
  ];

  const columns = [
    {
      dataField: 'avatar',
      text: 'Image',
      formatter: (cellContent, asset) => (
        <img src={asset.imageUri} alt={`Avatar of ${asset.title}`} width="80" />
      ),
    },
    {
      dataField: 'title',
      text: 'Title',
      filter: textFilter({ placeholder: '' }),
      sort: true,
    },
    {
      dataField: 'content',
      text: 'Content',
      filter: textFilter({ placeholder: '' }),
      sort: true,
    },
    {
      dataField: 'externalUrl',
      text: 'External URL',
      sort: true,
    },
    {
      dataField: 'promoCode',
      text: 'Promo Code',
      sort: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Last updated',
      sort: true,
      formatter: (cell) => moment(cell).format('DD MMM YYYY hh:mm A'), // Adjust the format as needed.
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      formatter: (cellContent, row) => (
        <div className="table-actions">
          <span
            className="action-icon"
            onClick={(e) => {
              e.stopPropagation();
              handlePushMessageClick(row);
            }}
          >
            <FaCommentAlt size={20} color="#328bf9" />
          </span>
          <span
            className="action-icon"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedPromo(row);
              setShowEditModal(true);
            }}
          >
            <FaEdit size={20} color="#328bf9" />
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <PromosFilter count={data.length} onChange={() => {}} />
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={data}
        columns={columns}
        pagination={paginationFactory({ sizePerPage: 50 })}
        filter={filterFactory()}
        rowStyle={getRowStyle}
        rowEvents={rowEvents}
        defaultSorted={defaultSorted}
        hover
      />
      {showCampaignPushMessageModal && (
        <PushMessageModal
          layerId={selectedPromo?._id}
          promoData={selectedPromo}
          onHideModal={() => resetModals()}
        />
      )}
      {showEditModal && (
        <PromoModal data={selectedPromo} onHideModal={() => resetModals()} />
      )}
    </>
  );
};

export default memo(PromosTable);
